<template>
  <div class="main-admin-container">
    <AdminSidebar
      :isOpened="isSidebarOpen"
      :isCloseBtnSwown="isCloseBtnShown"
      :options="sidebarOptions"
      :appOffsetWidth="appDiv.offsetWidth"
      @closeSidebar="toggleSidebar"
    />
    <div class="main-content">
      <router-view
        @openSidebar="toggleSidebar()"
      />
    </div>
  </div>
</template>

<script>
import AdminSidebar from './adminSidebar/AdminSidebar.vue'
export default {
  components: {
    AdminSidebar
  },
  data(){
    return {
      appDiv: null,
      isSidebarOpen: true,
      isCloseBtnShown:true,
      isSidebarCloseBtnShown: false,
      sidebarOptions: [
        'Members',
        'Billing',
        'Settings',
      ]
    }
  },
  created() {
    this.appDiv = document.getElementById('app')
  },
  watch: {
    appDiv(newValue, oldValue) {
      if (newValue.offsetWidth <= 1024) {
        this.isSidebarOpen = false
        this.isSidebarCloseBtnShown = true
        return
      }

      if (newValue.offsetWidth > 1024) {
        this.isSidebarOpen = true
        this.isSidebarCloseBtnShown = false
        return
      }
    },
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/styleVars.scss';

  .main-admin-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: $mainBackgroundColor;

    .main-content {
      width: calc(100% - 240px);
      height: 100%;
      margin-left: 240px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;
      overflow-y: scroll;
    }
  }

  @media (max-width: 1024px) {
    .main-admin-container {
      .main-content {
        width: 100%;
        margin-left: 0;
      }
    }
  }
</style>

<template>
  <div 
    class="admin-sidebar"
    :class="{
      'admin-sidebar-opened': isOpened,
    }"
  >
    <div class="admin-sidebar-header">
      <div 
        v-if="isCloseBtnShown"
        class="close-sidebar-btn"
        @click="$emit('closeSidebar')"
        >
      </div>
      <div class="sidebar-logo"></div>
    </div>
    <AdminSidebarElement
      v-for="(option, index) in options"
      :key="index"
      :title="option"
      :appOffsetWidth="appOffsetWidth"
      @closeSidebar="$emit('closeSidebar')"
    />
    <FeedbackBtn />
  </div>
</template>

<script>
import AdminSidebarElement from './AdminSidebarElement.vue'
import FeedbackBtn from '../../common/FeedbackBtn.vue'

export default {
  props: [
    'options',
    'isOpened',
    'isCloseBtnShown',
    'appOffsetWidth',
  ],
  components: {
    AdminSidebarElement,
    FeedbackBtn,
  },
}
</script>

<style lang="scss">
  @import '../../../assets/styleVars.scss';

  .admin-sidebar {
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 200;
    background-color: $blackColor;
    transition: all 0.2s;

    .admin-sidebar-header {
      width: 100%;
      height: 134px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .close-sidebar-btn {
        position: absolute;
        left: 16px;
        top: 16px;
        width: 24px;
        height: 24px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 24px 24px;
        background-image: url('../../../assets/icons/close-white-icon.svg');
        cursor: pointer;
      }

      .sidebar-logo {
        width: 190px;
        height: 90px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 190px 90px;
        background-image: url('../../../assets/images/simple_sales_logo_white.png');
      }
    }
  }
  .admin-sidebar-opened {
    width: 240px;
  }
</style>

<style lang="scss" scoped>
  @import '../../../assets/styleVars.scss';

  .feedback-btn {
    color: $fontColorWhite;
  }
</style>
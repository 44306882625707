<template>
  <div
    class="admin-sidebar-element-wrapper"
    @click="navigateToChosenOption(title)"
  >
    <div
      class="admin-sidebar-element"
      :class="{'admin-sidebar-element-active-state': currentOption === title}"
    >
      <div
        class="admin-sidebar-element-icon"
        :class="{
          'members-active-icon': title === 'Members' && currentOption === title,
          'members-icon': title === 'Members',
          'billing-active-icon': title === 'Billing' && currentOption === title,
          'billing-icon': title === 'Billing',
          'settings-active-icon': title === 'Settings' && currentOption === 'Settings',
          'settings-icon': title === 'Settings',
        }"
      >
      </div>
      <div class="admin-sidebar-element-title">{{title}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'title',
    'activeOptions',
    'appOffsetWidth',
  ],
  computed:{
    isActiveOption() {

    },
    currentOption() {
      return this.$route.name
    }
  },
  methods: {
    navigateToChosenOption(option) {
      this.$emit('setActiveOptions', option)

      if (this.appOffsetWidth <= 1024 ) this.$emit('closeSidebar')

      this.$router.push({ name: option})
    }
  }
}
</script>

<style lang="scss">
  @import '../../../assets/styleVars.scss';

  .admin-sidebar-element {
    width: 100%;
    height: 52px;
    position: relative;
    font-size: $fontSizeStandart;
    color: $fontColorSidebarGray;
    display: flex;
    align-items: center;
    border-left: 4px solid $blackColor;
    cursor: pointer;

    .admin-sidebar-element-icon {
      width: 24px;
      height: 24px;
      margin-left: 26px;
      margin-right: 16px;
    }

    .members-icon {
      background-image: url('../../../assets/icons/members-gray-icon.svg');
    }

    .members-active-icon {
      background-image: url('../../../assets/icons/members-white-icon.svg') !important;
    }

    .billing-active-icon {
      background-image: url('../../../assets/icons/billing-white-icon.svg') !important;
    }

    .billing-icon {
      background-image: url('../../../assets/icons/billing-gray-icon.svg');
    }

    .settings-active-icon {
      background-image: url('../../../assets/icons/settings-white-icon.svg') !important;
    }

    .settings-icon {
      background-image: url('../../../assets/icons/settings-gray-icon.svg');
    }

    .admin-sidebar-element-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .admin-sidebar-element-active-state {
    color: $fontColorWhite;
    border-left: 4px solid $appActionColor;
    background-color: $selectedBlackColor;
  }
</style>
